// extracted by mini-css-extract-plugin
export var card = "research-module--card--b9a12";
export var footer_text = "research-module--footer_text--c0a67";
export var me = "research-module--me--548fd";
export var name = "research-module--name--ca202";
export var publication_abstract = "research-module--publication_abstract--a79ca";
export var publication_author = "research-module--publication_author--c575c";
export var publication_links = "research-module--publication_links--7b0d6";
export var publication_venue = "research-module--publication_venue--8e15e";
export var section_title = "research-module--section_title--65155";
export var time = "research-module--time--c73b6";
export var wrapper = "research-module--wrapper--25ed5";
export var wrapper_small_pad = "research-module--wrapper_small_pad--bd88a";
export var wrapper_white = "research-module--wrapper_white--99e0b";