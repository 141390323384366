// extracted by mini-css-extract-plugin
export var abstract = "bio-module--abstract--4d028";
export var beforename = "bio-module--beforename--e8c07";
export var bio = "bio-module--bio--7c31e";
export var container = "bio-module--container--f4afe";
export var external_link_btn = "bio-module--external_link_btn--d8d85";
export var external_links = "bio-module--external_links--626cd";
export var grid = "bio-module--grid--acf09";
export var headshot = "bio-module--headshot--f9ea4";
export var headshot_container = "bio-module--headshot_container--c65ef";
export var name = "bio-module--name--b0be6";